// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-archive-tsx": () => import("./../../../src/pages/archive.tsx" /* webpackChunkName: "component---src-pages-archive-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-presentation-1-tsx": () => import("./../../../src/pages/presentation/1.tsx" /* webpackChunkName: "component---src-pages-presentation-1-tsx" */),
  "component---src-pages-presentation-2-tsx": () => import("./../../../src/pages/presentation/2.tsx" /* webpackChunkName: "component---src-pages-presentation-2-tsx" */),
  "component---src-pages-presentation-3-tsx": () => import("./../../../src/pages/presentation/3.tsx" /* webpackChunkName: "component---src-pages-presentation-3-tsx" */),
  "component---src-pages-presentation-4-tsx": () => import("./../../../src/pages/presentation/4.tsx" /* webpackChunkName: "component---src-pages-presentation-4-tsx" */),
  "component---src-pages-presentation-5-tsx": () => import("./../../../src/pages/presentation/5.tsx" /* webpackChunkName: "component---src-pages-presentation-5-tsx" */),
  "component---src-pages-presentation-6-tsx": () => import("./../../../src/pages/presentation/6.tsx" /* webpackChunkName: "component---src-pages-presentation-6-tsx" */),
  "component---src-pages-presentation-7-tsx": () => import("./../../../src/pages/presentation/7.tsx" /* webpackChunkName: "component---src-pages-presentation-7-tsx" */),
  "component---src-pages-presentation-8-tsx": () => import("./../../../src/pages/presentation/8.tsx" /* webpackChunkName: "component---src-pages-presentation-8-tsx" */),
  "component---src-pages-presentation-9-tsx": () => import("./../../../src/pages/presentation/9.tsx" /* webpackChunkName: "component---src-pages-presentation-9-tsx" */),
  "component---src-pages-presentation-index-tsx": () => import("./../../../src/pages/presentation/index.tsx" /* webpackChunkName: "component---src-pages-presentation-index-tsx" */),
  "component---src-pages-presentation-typescript-about-how-when-use-typescript-tsx": () => import("./../../../src/pages/presentation/typescript/about/how-when-use-typescript.tsx" /* webpackChunkName: "component---src-pages-presentation-typescript-about-how-when-use-typescript-tsx" */),
  "component---src-pages-presentation-typescript-about-why-use-typescript-tsx": () => import("./../../../src/pages/presentation/typescript/about/why-use-typescript.tsx" /* webpackChunkName: "component---src-pages-presentation-typescript-about-why-use-typescript-tsx" */),
  "component---src-pages-presentation-typescript-about-worries-of-users-who-do-not-use-typescript-tsx": () => import("./../../../src/pages/presentation/typescript/about/worries-of-users-who-do-not-use-typescript.tsx" /* webpackChunkName: "component---src-pages-presentation-typescript-about-worries-of-users-who-do-not-use-typescript-tsx" */),
  "component---src-pages-presentation-typescript-ot-tsx": () => import("./../../../src/pages/presentation/typescript/ot.tsx" /* webpackChunkName: "component---src-pages-presentation-typescript-ot-tsx" */),
  "component---src-pages-resume-tsx": () => import("./../../../src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */),
  "component---src-templates-post-template-tsx": () => import("./../../../src/templates/PostTemplate.tsx" /* webpackChunkName: "component---src-templates-post-template-tsx" */)
}

